import React from "react";

import Helmet from "react-helmet";

import Footer from "@components/footer";
import Header from "@components/header";

import { Select } from "antd";

import * as styles from "./index.module.scss";

import pcImg from "@src/images/help/pcBg.png";
import phoneImg from "@src/images/help/phoneBg.png";

import Icon1 from "@src/images/help/Frame.png";
import Icon2 from "@src/images/help/Group 4388.png";
import Icon3 from "@src/images/help/Frame (1).png";
import Icon4 from "@src/images/help/Frame (2).png";
import Icon5 from "@src/images/help/Frame (3).png";
import Icon6 from "@src/images/help/Frame (4).png";
import Icon7 from "@src/images/help/Frame (5).png";
import Icon8 from "@src/images/help/Frame (6).png";
import Icon9 from "@src/images/help/Frame (7).png";
import Icon10 from "@src/images/help/Frame (8).png";
import Icon11 from "@src/images/help/Frame (9).png";
import Icon12 from "@src/images/help/Frame (10).png";
import Icon13 from "@src/images/help/Frame (11).png";
import Icon14 from "@src/images/help/Frame (12).png";
import Icon15 from "@src/images/help/Frame (13).png";
import Icon16 from "@src/images/help/Frame (14).png";
import Icon17 from "@src/images/help/Frame (15).png";
import Icon18 from "@src/images/help/Frame (16).png";
import Icon19 from "@src/images/help/Frame (17).png";
import Icon20 from "@src/images/help/Frame (18).png";

const SearchIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 26 19"
    //   fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333984 0.333344H23.0569V23.0565H0.333984V0.333344Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6962 2.08334C6.39598 2.08334 2.08398 6.39418 2.08398 11.6943C2.08398 16.9945 6.39598 21.3065 11.6962 21.3065C16.9952 21.3065 21.3072 16.9945 21.3072 11.6943C21.3072 6.39418 16.9952 2.08334 11.6962 2.08334ZM11.6962 23.0565C5.43115 23.0565 0.333984 17.9593 0.333984 11.6943C0.333984 5.42934 5.43115 0.333344 11.6962 0.333344C17.9612 0.333344 23.0572 5.42934 23.0572 11.6943C23.0572 17.9593 17.9612 23.0565 11.6962 23.0565Z"
      fill="#686666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1133 18.6581H23.9747V24.5087H18.1133V18.6581Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0999 24.5087C22.8771 24.5087 22.6531 24.4235 22.4816 24.2532L18.3702 20.1535C18.0284 19.8117 18.0272 19.2575 18.3691 18.9157C18.7097 18.5715 19.2639 18.5738 19.6069 18.9133L23.7182 23.0142C24.0601 23.356 24.0612 23.909 23.7194 24.2508C23.5491 24.4235 23.3239 24.5087 23.0999 24.5087Z"
      fill="#686666"
    />
  </svg>
);

const renderDatas = [
  {
    title: "快速搭建店铺",
    detail: [
      {
        name: "店铺装修",
        icon: Icon1,
        target: "https://help.hotishop.com/dian-pu-guan-li/untitled",
      },
      {
        name: "新增商品",
        icon: Icon2,
        target:
          "https://help.hotishop.com/shang-pin-guan-li-1/shang-pin-guan-li/xin-zeng-shang-pin",
      },
      {
        name: "物流设置",
        icon: Icon3,
        target: "https://help.hotishop.com/she-zhi/she-zhi-wu-liu-fang-an",
      },
      {
        name: "结账设置",
        icon: Icon4,
        target: "https://help.hotishop.com/she-zhi/she-zhi-jie-zhang-she-zhi",
      },
    ],
  },
  {
    title: "促销方案",
    detail: [
      {
        name: "优惠码",
        icon: Icon5,
        target: "https://help.hotishop.com/ying-xiao-huo-dong-1/you-hui-ma",
      },
      {
        name: "自动优惠",
        icon: Icon6,
        target:
          "https://help.hotishop.com/ying-xiao-huo-dong-1/zi-dong-you-hui",
      },
      {
        name: "梯度优惠",
        icon: Icon7,
        target: "https://help.hotishop.com/ying-xiao-huo-dong-1/ti-du-you-hui",
      },
      {
        name: "满送优惠",
        icon: Icon8,
        target:
          "https://help.hotishop.com/ying-xiao-huo-dong-1/man-song-you-hui",
      },
    ],
  },
  {
    title: "数据运营",
    detail: [
      {
        name: "数据分析",
        icon: Icon9,
        target: "https://help.hotishop.com/shu-ju-zhong-xin-1/shu-ju-zhong-xin",
      },
      {
        name: "实时视图",
        icon: Icon10,
        target: "https://help.hotishop.com/shu-ju-zhong-xin-1/shi-shi-shi-tu",
      },
      {
        name: "销售报告",
        icon: Icon11,
        target:
          "https://help.hotishop.com/shu-ju-zhong-xin-1/xiao-shou-bao-gao",
      },
      {
        name: "广告助手",
        icon: Icon12,
        target: "#",
      },
    ],
  },
  {
    title: "店铺管理",
    detail: [
      {
        name: "客户管理",
        icon: Icon13,
        target: "https://help.hotishop.com/gu-ke-guan-li",
      },
      {
        name: "订单管理",
        icon: Icon14,
        target: "https://help.hotishop.com/ding-dan-guan-li/ding-dan-guan-li",
      },
      {
        name: "店铺设置",
        icon: Icon15,
        target:
          "https://help.hotishop.com/deng-lu/mshop-id#ru-he-zai-duo-ge-dian-pu-zhi-jian-jin-hang-qie-huan",
      },
      {
        name: "员工权限",
        icon: Icon16,
        target:
          "https://help.hotishop.com/deng-lu/mshop-id#ru-he-shi-yong-mshop-id-deng-lu-dian-pu",
      },
    ],
  },
  {
    title: "生态应用",
    detail: [
      {
        name: "数据跟踪",
        icon: Icon17,
        target:
          "https://help.hotishop.com/apps/cha-jian-pixel-conversion#kai-qi-pixel-conversion",
      },
      {
        name: "运营工具",
        icon: Icon18,
        target: "https://help.hotishop.com/apps",
      },
      {
        name: "效率工具",
        icon: Icon19,
        target: "https://help.hotishop.com/apps",
      },
      {
        name: "广告投放",
        icon: Icon20,
        target: "https://help.hotishop.com/apps",
      },
    ],
  },
];
const { Option } = Select;
let OPTIONS = [];
renderDatas.forEach((item) => {
  OPTIONS = [...OPTIONS, ...item.detail];
});

const isBrowser = typeof window !== "undefined";

const HelpCenterPage = () => {
  //   console.log(OPTIONS);
  const onChange = (value) => {
    console.log(`selected ${value}`);
    if (isBrowser) {
      let result = OPTIONS.filter((item) => item.name === value)[0];
      console.log(result);
      window.location.href = result.target;
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>帮助中心-Shoprise 官网</title>
      </Helmet>
      <Header />
      <div className={styles.banner}>
        <picture>
          <source srcSet={pcImg} media="(min-width: 960px)" />
          <source srcSet={phoneImg} media="(max-width: 960px)" />
          <img src={pcImg} alt="pic" style={{ width: "100%" }} />
        </picture>
        <div className={styles.bannerSelect}>
          <Select
            showSearch
            onChange={onChange}
            placeholder={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginRight: "10px" }}>输入关键词以搜索</div>
                <div style={{ position: "relative", top: "2px" }}>
                  <SearchIcon />
                </div>
              </div>
            }
          >
            {OPTIONS.map((item) => (
              <Option value={item.name} key={item.name}>
                <div style={{ width: "100%", color: "#0075FF" }}>
                  {item.name}
                </div>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className={styles.helpCenterPage}>
        {renderDatas.map((item, index) => (
          <div className={styles.helpItem} key={index}>
            <div className={styles.helpTitle}>{item.title}</div>
            <div className={styles.itemGroup}>
              {item.detail.map((secItem) => (
                <div className={styles.item} key={secItem.name}>
                  <div className={styles.itemImg}>
                    <img src={secItem.icon} alt={secItem.icon} />
                  </div>
                  {/* {secItem.name} */}
                  <div>{secItem.name}</div>
                </div>

              ))}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default HelpCenterPage;
